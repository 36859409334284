import React, { useMemo } from "react";
import { Chip, ChipProps } from "@mui/material";
import { AttendStatus, AttendStatusMap } from "../../app/services/events/types";
import { useTranslation } from "react-i18next";

export interface GuestStatusChipProps
  extends Omit<ChipProps, "color" | "variant" | "label"> {
  attendStatus: AttendStatus;
  isAfterConfirm?: boolean;
}

export function GuestStatusChip({
  attendStatus,
  isAfterConfirm,
  ...props
}: GuestStatusChipProps) {
  const { t } = useTranslation("guestInfo");
  const statusLabelName = useMemo(() => {
    return isAfterConfirm ? "statusAfterConfirm" : "status";
  }, [isAfterConfirm]);
  const themeByAttendStatus = useMemo((): Pick<
    ChipProps,
    "color" | "variant"
  > => {
    switch (`${attendStatus}`) {
      case `${AttendStatusMap.UNSETTLED}`:
        return {
          color: "default",
          variant: "outlined",
        };
      case `${AttendStatusMap.SETTLED}`:
        return {
          color: "primary",
          variant: "filled",
        };
      case `${AttendStatusMap.UNAVAILABLE}`:
        return {
          color: "default",
          variant: "filled",
        };
      default:
        return {
          color: "default",
          variant: "outlined",
        };
    }
  }, [attendStatus]);

  return (
    <Chip
      label={t(`${statusLabelName}.${attendStatus}`)}
      color={themeByAttendStatus.color}
      variant={themeByAttendStatus.variant}
      {...props}
    />
  );
}
