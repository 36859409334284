import { AttendStatus } from "../app/services/events/types";
import { useEffect, useState } from "react";

/*
이벤트 참석 여부 status 데이터를 boolean으로 변환
0: 미정
1: 참가
2: 불참
 */
export function useEventAttendStatus(
  attendData: { status: AttendStatus } | undefined
) {
  const [attend, setAttend] = useState(true);

  useEffect(() => {
    setAttend(attendData?.status !== 2);
  }, [attendData]);

  return attend;
}
