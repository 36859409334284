import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { History } from "history";

interface ScrollToTopProps {
  history: History;
}

function ScrollToTop({ history }: ScrollToTopProps) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return <React.Fragment></React.Fragment>;
}

export default withRouter(ScrollToTop);
