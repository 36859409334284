import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import {
  momentLocalizer,
  Event,
  stringOrDate,
  View,
  Views,
} from "react-big-calendar";
import moment from "moment";

import {
  AttendStatusMap,
  GuestSchedule,
  GuestWithStatus,
  RecommendSchedule,
} from "../../app/services/events/types";
import { BaseCalendar, ExtendedEventWithSchedule } from "./BaseCalendar";
import { convertScheduleToEvent } from "../../utils/convertScheduleToEvent";
import { getUUID } from "../../utils/getUUID";
import { ConfirmScheduleCalendarModal } from "./ConfirmScheduleCalendarModal";
import { splitScheduleRanges } from "../../utils/splitScheduleRanges";

const localizer = momentLocalizer(moment);

const selectedScheduleColors = {
  bgColor: "rgba(255, 69, 69, 0.41)",
  strokeColor: "rgb(255, 69, 69)",
};

export function getEventWithRecommendedSchedule(
  event: Event,
  guestSchedules: GuestSchedule[],
  participants: GuestWithStatus[]
) {
  // recommended schedules를 탐색하여 가능 참가자 확인
  const eventStart = moment(event.start);
  const eventEnd = moment(event.end);

  const availableParticipants = participants.map((p) => {
    const guestSchedule = guestSchedules.find((d) => d.email === p.email);
    let status = p.status;

    if (status !== 2 && guestSchedule) {
      const overlapped = guestSchedule.schedules.some((d) => {
        const scheduleStart = moment(d.from_time);
        const scheduleEnd = moment(d.to_time);
        return (
          scheduleStart.isSameOrBefore(eventStart) &&
          scheduleEnd.isSameOrAfter(eventEnd)
        );
      });
      if (overlapped) status = 1;
      else status = 0; // overlap 아닌 경우 처리
    }

    return { ...p, status };
  });

  const createdEvent: ExtendedEventWithRecommendedSchedule = {
    ...event,
    ...selectedScheduleColors,
    id: `event-${getUUID()}`,
    isActive: true,
    isTemp: true,
    title: "",
    originSchedule: {
      id: -1, // 음수로 id 설정
      from_time: eventStart.toISOString(),
      to_time: eventEnd.toISOString(),
      guests: availableParticipants,
    },
  };

  return createdEvent;
}

export type ExtendedEventWithRecommendedSchedule =
  ExtendedEventWithSchedule<RecommendSchedule>;

export interface CalendarRange {
  start: stringOrDate;
  end: stringOrDate;
}
export interface ConfirmScheduleCalendarProps {
  me: string;
  requiredParticipant?: GuestWithStatus; // 필수 참석자
  totalParticipants: GuestWithStatus[];
  selectedSchedule?: RecommendSchedule; // 리스트에서 선택된 경우
  guestSchedules: GuestSchedule[];
  recommendedSchedules: RecommendSchedule[];
  onMonthViewEventCreate?: (event?: Event) => void;
  onRangeChange?: (range: CalendarRange) => void;
  onSubmit?: (event?: ExtendedEventWithRecommendedSchedule) => void;
}

export function ConfirmScheduleCalendar({
  totalParticipants,
  requiredParticipant,
  selectedSchedule,
  guestSchedules,
  recommendedSchedules,
  onMonthViewEventCreate,
  onRangeChange,
  onSubmit,
}: ConfirmScheduleCalendarProps) {
  const { t } = useTranslation("confirmScheduleCalendar");
  const [eventsFromSchedules, setEventsFromSchedules] = useState<
    ExtendedEventWithRecommendedSchedule[]
  >([]);
  const [activeEvents, setActiveEvents] = useState<
    ExtendedEventWithRecommendedSchedule[]
  >([]); // 직접 선택 이벤트
  const [passiveEvents, setPassiveEvents] = useState<
    ExtendedEventWithRecommendedSchedule[]
  >([]);
  const [selectedEvent, setSelectedEvent] =
    useState<ExtendedEventWithRecommendedSchedule | null>(null);
  const [settledParticipatns, setSetteldParticipants] = useState<
    GuestWithStatus[]
  >([]);
  const [isModalOpen, setIsOpenModal] = useState(false);

  const handleEventSelect = useCallback(
    (event: ExtendedEventWithRecommendedSchedule) => {
      setSelectedEvent(event);
      setIsOpenModal(true);
    },
    []
  );

  const handleEventCreate = useCallback(
    (event: Event, view?: View) => {
      if (view === Views.MONTH) {
        if (onMonthViewEventCreate) onMonthViewEventCreate(event);
      } else {
        // recommended schedules를 탐색하여 가능 참가자 확인
        const createdEvent = getEventWithRecommendedSchedule(
          event,
          guestSchedules,
          totalParticipants
        );
        setActiveEvents([createdEvent]);
        setSelectedEvent(createdEvent);
        setIsOpenModal(true);
      }
    },
    [guestSchedules, totalParticipants, onMonthViewEventCreate]
  );

  const handleModalClose = useCallback(() => {
    setIsOpenModal(false);
    setActiveEvents((events) => events.filter((d) => !d.isTemp));
    //setSelectedEvent(null); // 삭제된 이벤트가 노출되버림
  }, []);

  const handleModalSubmit = useCallback(() => {
    if (onSubmit && selectedEvent) onSubmit(selectedEvent); // selectedEvent.originSchedule 을 사용
    handleModalClose();
  }, [onSubmit, selectedEvent, handleModalClose]);

  const getEventInfoFromSchedule = useCallback(
    ({ guests }: RecommendSchedule) => {
      const hasAllParticipants =
        guests.filter((g) => g.status === AttendStatusMap.SETTLED).length ===
        guests.length;

      const title = hasAllParticipants
        ? t("HasAllParticipants")
        : guests.map((g) => g.name || g.email).join(",");
      return { hasAllParticipants, title };
    },
    [t]
  );

  const handleRangeChange = useCallback(
    (range) => {
      if (Array.isArray(range)) {
        range = {
          start: range[0],
          end: range[range.length - 1],
        };
      } else {
        range = {
          start: moment(range.start).toDate(),
          end: moment(range.end).toDate(),
        };
      }
      if (onRangeChange) {
        onRangeChange(range);
      }
    },
    [onRangeChange]
  );

  useEffect(() => {
    setSelectedEvent(null);
  }, [recommendedSchedules]);

  useEffect(() => {
    setSetteldParticipants(totalParticipants.filter((d) => d.status === 1));
  }, [totalParticipants]);

  useEffect(() => {
    // scheduels를 변형하기
    let hasSelected = false;
    const events = recommendedSchedules.reduce((result, schedule) => {
      const isSelected =
        selectedSchedule && selectedSchedule.id === schedule.id;
      if (isSelected) hasSelected = true;
      const { title, hasAllParticipants } = getEventInfoFromSchedule(schedule);
      // schedule을 split 한다.
      const splitRanges = splitScheduleRanges(schedule);
      const splitEvents = splitRanges.map(({ from_time, to_time }) => {
        return convertScheduleToEvent(
          {
            from_time,
            to_time,
          },
          {
            title,
            //name: title,
            bgColor: isSelected
              ? selectedScheduleColors.bgColor
              : hasAllParticipants
              ? undefined
              : schedule.guests[0]?.bg_color,
            strokeColor: isSelected
              ? selectedScheduleColors.strokeColor
              : undefined,
            isActive: false,
            originSchedule: schedule,
          },
          localizer
        );
      });
      return result.concat(splitEvents);
    }, [] as ExtendedEventWithSchedule<RecommendSchedule>[]);
    if (hasSelected) {
      setActiveEvents([]); // 이미 선택된 event 있을 경우 기존 activeEvents 삭제
    }
    setEventsFromSchedules(events);
  }, [
    t,
    settledParticipatns,
    recommendedSchedules,
    selectedSchedule,
    getEventInfoFromSchedule,
  ]);

  useEffect(() => {
    if (selectedSchedule && selectedSchedule.id < 0) {
      // 직접 입력된 임시 schedule 일 경우 id 음수로 둘 예정
      const { title } = getEventInfoFromSchedule(selectedSchedule);
      // schedule을 split 한다.
      const splitRanges = splitScheduleRanges(selectedSchedule);
      const events = splitRanges.map(({ from_time, to_time }) => {
        return convertScheduleToEvent(
          {
            from_time,
            to_time,
          },
          {
            title,
            //name: title,
            isActive: true,
            originSchedule: selectedSchedule,
            ...selectedScheduleColors,
          },
          localizer
        );
      });

      setActiveEvents(events);
    }
  }, [selectedSchedule, getEventInfoFromSchedule]);

  useEffect(() => {
    // setPassiveEvents;
    if (requiredParticipant) {
      setPassiveEvents(
        eventsFromSchedules.filter((d) => {
          const schedule = d.originSchedule as RecommendSchedule;
          return schedule.guests.some(
            (g) => g.email === requiredParticipant.email
          );
        })
      );
    } else {
      setPassiveEvents(eventsFromSchedules);
    }
  }, [requiredParticipant, eventsFromSchedules]);

  return (
    <Box>
      <BaseCalendar
        activeEvents={activeEvents}
        passiveEvents={passiveEvents}
        localizer={localizer}
        onRangeChange={handleRangeChange}
        onEventCreate={handleEventCreate}
        onEventSelect={handleEventSelect}
      />
      <ConfirmScheduleCalendarModal
        open={isModalOpen}
        event={selectedEvent}
        onSubmit={handleModalSubmit}
        onClose={handleModalClose}
      />
    </Box>
  );
}
