import React, { useCallback } from "react";
import { TextField, TextFieldProps } from "@mui/material";
import styled from "@mui/styled-engine";

export type TextFieldWithValidationProps = {
  errorMessage?: string;
  onValueChange?: (value?: string) => void;
  onSubmit?: () => void;
} & TextFieldProps;

const StyledTextFieldWithValidation = styled(TextField)`
  .MuiSelect-nativeInput {
    border: 0;
    top: 0;
    opacity: 1;
    padding: 16.5px 14px;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    &:not([value=""]) {
      opacity: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .MuiInput-underline.MuiSelect-root {
    &:before {
      border-bottom: 0;
    }
    & > .MuiSelect-select {
      padding: 0 24px 0 0;
    }
  }
`;

export function TextFieldWithValidation(props: TextFieldWithValidationProps) {
  const {
    value,
    errorMessage,
    type,
    helperText,
    onValueChange,
    multiline,
    fullWidth = true,
    placeholder = "",
    required = false,
    children,
    onSubmit = () => {},
  } = props;
  const handleChange = useCallback<
    NonNullable<React.ComponentProps<typeof TextField>["onChange"]>
  >(
    (e) => {
      if (onValueChange) onValueChange(e.target.value);
    },
    [onValueChange]
  );

  const handleKeyPress = useCallback(
    (ev) => {
      if (ev.key === "Enter" && !multiline) {
        ev.preventDefault();
        onSubmit?.();
      }
    },
    [onSubmit, multiline]
  );

  return (
    <StyledTextFieldWithValidation
      fullWidth={fullWidth}
      variant="outlined"
      {...props}
      placeholder={placeholder ? `${placeholder}${required ? " *" : ""}` : ""}
      type={type}
      value={value || ""}
      error={errorMessage !== undefined}
      helperText={errorMessage || helperText}
      autoComplete={type === "password" ? "new-password" : undefined}
      onChange={handleChange}
      onKeyPress={handleKeyPress}
    >
      {children}
    </StyledTextFieldWithValidation>
  );
}
