import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import { authSlice } from "../features/auth/authSlice";
import { resetApi } from "./services/accounts/resetApi";
import { googleApi } from "./services/accounts/googleApi";
import { authApi } from "./services/accounts/authApi";
import { verifyApi } from "./services/accounts/verifyApi";
import { eventApi } from "./services/events/eventApi";
import { PersistConfig } from "redux-persist/es/types";
import { rtkQueryErrorLogger } from "./rtkQueryErrorLogger";
import { globalSlice } from "../features/global/globalSlice";

const rootReducer = combineReducers({
  [globalSlice.name]: globalSlice.reducer,
  [authSlice.name]: authSlice.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [resetApi.reducerPath]: resetApi.reducer,
  [verifyApi.reducerPath]: verifyApi.reducer,
  [googleApi.reducerPath]: googleApi.reducer,
  [eventApi.reducerPath]: eventApi.reducer,
});
const persistConfig: PersistConfig<any> = {
  key: "root",
  version: 1,
  storage,
  whitelist: [authSlice.name],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  // @ts-ignore
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(authApi.middleware)
      .concat(resetApi.middleware)
      .concat(verifyApi.middleware)
      .concat(googleApi.middleware)
      .concat(eventApi.middleware)
      .concat(rtkQueryErrorLogger),
});

export const Persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
