import React, { useCallback, useState } from "react";
import {
  Box,
  IconButton,
  Snackbar,
  Typography,
  Button,
  Link,
} from "@mui/material";
import styled, { css } from "@mui/styled-engine";
import { useTranslation } from "react-i18next";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/SendOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const StyledUrlView = styled(Box)`
  position: relative;
  padding: 16px 20px;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  > .url-view {
    &-title {
      margin-top: -4px;
      font-size: 14px;
      font-weight: 700;
      color: #a7a7a7;
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &-url {
      display: flex;
      align-items: center;
      > span {
        font-size: 18px;
        font-weight: 600;
        color: #000;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        flex: 1;
      }
    }
  }
`;

const StyledUrlWarning = styled(Box)`
  margin-top: 20px;
  > .url-warning {
    &-title {
      font-weight: bold;
      font-size: 14px;
      color: #ff4747;
    }
    &-description {
      font-size: 14px;
      color: #767676;
      white-space: pre-line;
    }
  }
`;

const StyledButtonContainer = styled(Box)`
  white-space: nowrap;
  display: inline-block;
`;

const StyledActionButton = styled(Button)`
  font-size: 14px;
  font-weight: 600;
  color: #333;
  min-width: auto;
  padding: 4px;
  line-height: 1.2;
  white-space: nowrap;
  margin-right: 22px;
  &:last-of-type {
    margin-right: 0;
  }
`;

const iconCss = css`
  font-size: 14px;
  margin-right: 6px;
`;

const ShareIcon = styled(SendIcon)`
  ${iconCss}
`;

const CopyIcon = styled(ContentCopyIcon)`
  ${iconCss}
`;

interface UrlViewProps {
  url?: string;
  showWarning?: boolean;
  title?: string;
}

export function UrlView({ url, title, showWarning = true }: UrlViewProps) {
  const { t } = useTranslation("urlView");

  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

  const handleSnackbarClose = useCallback(() => {
    setIsSnackbarOpen(false);
  }, []);

  const isShareAvailable = window.navigator.share;

  const webShare = () => {
    if (window.navigator.share) {
      window.navigator.share({
        title,
        text: t("ShareText", {
          title,
        }),
        url,
      });
    }
  };

  return (
    <>
      <StyledUrlView component="div">
        <Typography component="span" className="url-view-title">
          {t("Title")}
          <StyledButtonContainer>
            {isShareAvailable && (
              <StyledActionButton variant="text" onClick={webShare}>
                <ShareIcon />
                {t("Share")}
              </StyledActionButton>
            )}
            <CopyToClipboard
              text={url || ""}
              onCopy={() => setIsSnackbarOpen(true)}
            >
              <StyledActionButton variant="text">
                <CopyIcon />
                {t("Copy")}
              </StyledActionButton>
            </CopyToClipboard>
          </StyledButtonContainer>
        </Typography>
        <Box component="div" className="url-view-url">
          <Typography component="span" title={url}>
            <Link href={url} target={"_blank"} color="inherit">
              {url}
            </Link>
          </Typography>
        </Box>
      </StyledUrlView>
      {showWarning && (
        <StyledUrlWarning component="div">
          <Typography component="span" className="url-warning-title">
            {t("warning.Title")}
          </Typography>
          <Typography component="p" className="url-warning-description">
            {t("warning.Description")}
          </Typography>
        </StyledUrlWarning>
      )}
      <Snackbar
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleSnackbarClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
        open={isSnackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        message={t("CopiedMessage")}
      />
    </>
  );
}
