import { authApi } from "../app/services/accounts/authApi";
import { resetApi } from "../app/services/accounts/resetApi";
import { verifyApi } from "../app/services/accounts/verifyApi";
import { eventApi } from "../app/services/events/eventApi";

type ErrorMessage<T extends { endpoints: Record<string, unknown> }> = Record<
  keyof T["endpoints"],
  string
>;

export const errorMessages = {
  ko: {
    [authApi.reducerPath]: {
      postLogin: "인증에 실패하였습니다.",
      postRegister: "가입에 실패하였습니다.",
      deleteLogout: "로그아웃에 실패했습니다.",
      deleteUnregister: "계정 삭제에 실패했습니다.",
      putResetPasswordWithUser: "비밀번호 변경에 실패했습니다.",
    } as ErrorMessage<typeof authApi>,
    [resetApi.reducerPath]: {
      postResetPasswordSendEmail: "비밀번호 변경 메일 발송에 실패했습니다.",
      postResetPasswordVerifyEmail: "비밀번호 변경에 실패했습니다.",
      putResetPasswordWithToken: "비밀번호 변경에 실패했습니다.",
    } as ErrorMessage<typeof resetApi>,
    [verifyApi.reducerPath]: {
      postSendEmail: "인증 메일 발송에 실패했습니다.",
      postResendEmail: "인증 메일 재발송에 실패했습니다.",
      postVerifyEmail: "계정 인증에 실패했습니다.",
    } as ErrorMessage<typeof verifyApi>,
    [eventApi.reducerPath]: {
      postCreateEvent: "이벤트 생성에 실패했습니다.",
      postInviteGuest: "게스트 초대 변경에 실패했습니다.",
      putSelectSchedule: "일정 확정에 실패했습니다.",
      postInsertSchedule: "일정 확정에 실패했습니다.",
      putUpdateEvent: "이벤트 변경에 실패했습니다.",
      deleteCancelEvent: "이벤트 삭제에 실패했습니다.",
      postInviteResend: "초대 메일 재발송에 실패했습니다.",
      postSelectSchedules: "일정 선택에 실패했습니다.",
      deleteSchedules: "일정 삭제에 실패했습니다.",
      postAttendee: "참여 변경에 실패했습니다.",
      putUpdateStatus: "상태 변경에 실패했습니다.",
      postGuestsVerify: "게스트 인증에 실패했습니다.",
    } as ErrorMessage<typeof eventApi>,
  },
};
